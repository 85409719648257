<template>

  <div>
    <!-- Table Container Card -->
    <model-list-table
      :store-module-name="storeModuleName"
      :table-columns="tableColumns"
      :filters-list="filtersList"
      :query-params="getQueryParams"
      add-action="sidebar"
      :view-action="false"
      :preview-action="true"
      edit-action="sidebar"
      :delete-action="true"
      :extra-actions="[]"
      :sidebar="SidebarAddNewPage"
      :is-sortable="false"
    />
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, onMounted } from 'vue'
import { formatDate } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import SidebarAddNewPage from '@/views/models/pages/add/SidebarAddNewPage.vue'
import { VBToggle } from 'bootstrap-vue'
import pageStoreModule from '../pageStoreModule'

export default {
  components: {
    ModelListTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  methods: {
  },
  setup() {
    const STORE_MODULE_NAME = 'pages'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, pageStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, pageStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // const categoryFilter = ref(null)
    const statusFilter = ref(null)

    const tableColumns = [
      // {
      //   key: 'draggable',
      //   label: '',
      //   sortable: false,
      //   thClass: 'p-0',
      //   thStyle: 'width: 1px',
      //   tdClass: ['p-0', 'pl-1', 'draggable-handle', 'cursor-move'],
      // },
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'title', sortable: true, label: 'Page Title' },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDate,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDate,
        sortable: true,
      },
      { key: 'is_active', sortable: true, label: 'Status' },
      { key: 'actions' },
    ]

    const filtersList = [/* statusFilter */]
    const getQueryParams = () => ({
      // categories: categoryFilter.value,
      is_active: statusFilter.value,
    })

    return {
      // Extra Filters
      statusFilter,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      getQueryParams,

      SidebarAddNewPage,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}
</style>
