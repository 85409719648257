<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
  >
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>
            <div>
              <span class="pr-75">{{ `${settings.domain}.${subdomainHost}` }}</span>
              <b-badge
                  pill
                  :variant="settings.is_active ? 'light-primary' : 'light-danger'"
                  size="sm"
                  class="font-small-3 font-weight-normal"
              >
                {{ $t(settings.is_active ? 'modules.settings.status.active' : 'modules.settings.status.inactive') }}
              </b-badge>
            </div>
            <div>
              <small>{{ $t('modules.settings.sub_domain_card.title') }}</small>
              <feather-icon
                  v-if="!isBodyVisible"
                  v-b-tooltip.hover.v-info
                  class="ml-25 text-info"
                  icon="InfoIcon"
                  size="18"
                  :title="$t('modules.settings.sub_domain_card.description')"
              />
            </div>
          </b-card-title>
        </div>




        <div class="d-flex flex-row align-items-center">
          <!--          <b-button-->
          <!--              variant="light"-->
          <!--              @click="isBodyVisible = !isBodyVisible"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--                icon="EditIcon"-->
          <!--                size="18"-->
          <!--            />-->
          <!--            {{ $t('Edit') }}-->
          <!--          </b-button>-->

          <span
              class="mt-75"
              v-b-tooltip.hover.v-info
              :title="$t('modules.settings.inputs.is_domain_active:description')"
          >
            <i-input
                v-model="localSettings.is_active"
                type="switch"
                title=""
                @input="v => updateStatus(v)"
            >
              <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
              </span>
            <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
              </span>
          </i-input>
          </span>

          <b-button
              variant="outline-secondary"
              @click="isBodyVisible = !isBodyVisible"
          >
            <feather-icon
                icon="EditIcon"
                size="18"
            />
            {{ $t('Edit') }}
          </b-button>
        </div>
      </b-card-header>

      <b-card-body v-if="isBodyVisible">
        <!-- alert -->
        <b-col
            cols="12"
            class="mt-75"
        >
          <b-alert
              show
              variant="info"
              class="mb-50"
              dismissible
          >
            <div class="alert-body">
              <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
              />
              <span class="ml-25">{{ $t('modules.settings.sub_domain_card.description') }}</span>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <!-- form -->
        <b-form
            class="mt-2"
            @submit.prevent="onSubmitHandler"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                  #default="{ errors }"
                  :name="$t(`modules.settings.inputs.store_domain`)"
                  rules="required"
              >
                <b-form-group
                    :label="''"
                    label-for="store_subdomain`"
                >
                  <b-input-group>
                    <b-form-input
                        id="store_subdomain"
                        v-model="localSettings.domain"
                        type="text"
                        minlength="3"
                        maxlength="25"
                        required
                        :state="validationError('domain') ? false : null"
                        placeholder="mystore"
                    />
                    <template slot="append">
                      <b-input-group-append>
                        <b-input-group-text class="ltr bg-light">
                          https://
                        </b-input-group-text>
                      </b-input-group-append>
                    </template>
                    <template slot="prepend">
                      <b-input-group-prepend>
                        <b-input-group-text class="ltr bg-light">
                          .{{ subdomainHost }}
                        </b-input-group-text>
                      </b-input-group-prepend>
                    </template>
                  </b-input-group>

                  <small class="text-danger">{{ validationError('subdomain') }}</small>
                </b-form-group>

              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  :disabled="!hasTouchedAttributes || isLoading"
                  type="submit"
              >
                {{ $t(`Save Changes`) }}
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  :disabled="isLoading"
                  @click.prevent="resetForm"
              >
                {{ $t('Cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupText,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAlert,
  BCardTitle,
  BCardHeader,
  BCardSubTitle,
  BAvatar,
  BBadge,
  BMediaBody,
  BMediaAside, BMedia, BCardBody, BImg, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ref} from 'vue'
import {ValidationProvider} from 'vee-validate'
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

export default {
  components: {
    BOverlay,
    BImg,
    BCardActionsContainer,
    BCardBody,
    BMedia, BMediaAside, BMediaBody, BBadge, BAvatar,
    BCardSubTitle,
    BCardHeader, BCardTitle,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    ValidationProvider,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
      subdomainHost: this.settings?.suffix || 'kssib.com'
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
      this.isBodyVisible = false
    },
    onSubmitHandler() {
      const updatedAttributes = this.touchedAttributes
      this.isLoading = true
      this.$emit('submit', {
        settings: {
          id: this.settings.id,
          ...updatedAttributes,
        },
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    updateStatus(newValue) {
      this.isLoading = true
      this.$emit('submit', {
        settings: {
          id: this.settings.id,
          is_active: newValue,
        },
        callback: (result, error) => {
          this.isLoading = false
        },
      })
    },
    validationError(attr) {
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    }
  },
  setup() {
    const refInputEl = ref(null)
    const isBodyVisible = ref(false)

    return {
      refInputEl,

      isBodyVisible,
    }
  },
}
</script>
