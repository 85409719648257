<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="onSubmitHandler">
      <b-row>
        <b-col sm="12">
          <i-input
              v-model="localSettings.allow_guest_checkout"
              type="switch"
              title="modules.settings.inputs.allow_guest_checkout"
              description="modules.settings.inputs.allow_guest_checkout:description"
              :errors="validationErrorsArray('allow_guest_checkout')"
          />
        </b-col>
        <b-col sm="12" v-if="localSettings.allow_guest_checkout">
          <i-input
              v-model="localSettings.require_email_in_checkout"
              type="switch"
              title="modules.settings.inputs.require_email_in_checkout"
              description="modules.settings.inputs.require_email_in_checkout:description"
              :errors="validationErrorsArray('require_email_in_checkout')"
          />
        </b-col>
        <b-col sm="12">
          <i-input
              v-model="localSettings.allow_worldwide_checkout"
              type="switch"
              title="modules.settings.inputs.allow_worldwide_checkout"
              :description="$t('modules.settings.inputs.allow_worldwide_checkout:description', {country: getStoreCountryName})"
              :errors="validationErrorsArray('allow_worldwide_checkout')"
          />
          <b-alert
              :show="localSettings.allow_worldwide_checkout"
              variant="info"
              class="mb-50"
          >
            <div class="alert-body">
              <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
              />
              <span class="ml-25">{{ $t('modules.settings.inputs.allow_worldwide_checkout:note') }}</span>
            </div>
          </b-alert>
        </b-col>
        <b-col sm="12">
          <i-input
              v-model="localSettings.skip_cart_page"
              type="switch"
              title="modules.settings.inputs.skip_cart_page"
              :description="$t('modules.settings.inputs.skip_cart_page:description')"
              :errors="validationErrorsArray('skip_cart_page')"
          />
        </b-col>

        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!hasTouchedAttributes || isLoading"
              type="submit"
          >
            {{ $t(`Save Changes`) }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :disabled="isLoading"
              @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {getLocaleCurrency, shop} from "../../../../@core/utils/utils";

export default {
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (settingKey === 'phone') {
          currentValue = `+${this.localSettings.dialCode}${this.localSettings.phone}`
        }
        if (typeof (this.settings[settingKey]) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(this.settings[settingKey])) {
            attrs[settingKey] = currentValue
          }
        } else if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
    getStoreCountryName() {
      const storeCountry = this.shop()?.country
      const currentLocale = this.$i18n.locale
      if (storeCountry) {
        return storeCountry[`name_${currentLocale}`]
      }
      if (this.$i18n.locale === 'ar') {
        return 'دولتك'
      }
      return 'your country'
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    shop,
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      this.isLoading = true
      this.$emit('submit', {
        settings: this.touchedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      if (attr === 'confirmation_page_message') {
        return this.validationError('confirmation_page_message.ar') || this.validationError('confirmation_page_message.en')
      }
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
