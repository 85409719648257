<template>
  <b-card
    body-class="d-flex justify-content-between"
  >
    <span>
      <h5>{{ $t('theme_editor.announcement_card.title') }}</h5>
      <b-card-text class="font-small-3">
        {{ $t('theme_editor.announcement_card.description') }}
      </b-card-text>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{name: 'layoutSettings'}"
      >
        {{ $t('theme_editor.announcement_card.btn') }}
      </b-button>
    </span>
    <b-img
      :src="require('@/assets/images/pages/layout-builder.svg')"
      class="congratulation-medal0"
      style="height: 250px"
      alt="New Layout Settings"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
  },
}
</script>
