export default {
  name: 'domains',

  translatedAttributes: [],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    domain: item.domain,
    is_active: item.is_active,
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    id: data.id,
    domain: data.domain,
    suffix: data.suffix,
    nameservers: data.nameservers,
    dns_records: data.dns_records,
    is_custom_domain: data.is_custom_domain,
    is_active: !!data.is_active,
    status: data.status,
  }),

  resolveValidationErrors: errors => ({
    domain: errors.domain,
    is_active: errors.is_active,
  }),
}
