import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import domainModel from './domainModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  deleteOne,
} = useModelStoreModule(domainModel)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    create,
    update,
    deleteOne,
  },
}
