<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="onSubmitHandler">
      <b-row>
        <b-col sm="12">
          <i-input
              v-model="localSettings.title"
              type="text"
              max-length="1000"
              title="modules.settings.inputs.seo_title"
              description="modules.settings.inputs.seo_title:description"
              placeholder="modules.settings.inputs.seo_title:placeholder"
              :errors="validationErrorsArray('title')"
          />
          <i-input
              v-model="localSettings.description"
              type="text"
              max-length="1000"
              title="modules.settings.inputs.seo_description"
              description="modules.settings.inputs.seo_description:description"
              placeholder="modules.settings.inputs.seo_description:placeholder"
              :errors="validationErrorsArray('description')"
          />
          <i-input
              v-model="localSettings.keywords"
              type="text"
              max-length="1000"
              title="modules.settings.inputs.seo_keywords"
              description="modules.settings.inputs.seo_keywords:description"
              placeholder="modules.settings.inputs.seo_keywords:placeholder"
              :errors="validationErrorsArray('keywords')"
          />

          <!--          <b-alert-->
          <!--            show-->
          <!--            variant="warning"-->
          <!--            class="mb-50"-->
          <!--          >-->
          <!--            <h4 class="alert-heading">-->
          <!--              Your email is not confirmed. Please check your inbox.-->
          <!--            </h4>-->
          <!--            <div class="alert-body">-->
          <!--              <b-link class="alert-link">-->
          <!--                Resend confirmation-->
          <!--              </b-link>-->
          <!--            </div>-->
          <!--          </b-alert>-->
        </b-col>

        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!hasTouchedAttributes || isLoading"
              type="submit"
          >
            {{ $t(`Save Changes`) }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :disabled="isLoading"
              @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (typeof (this.settings[settingKey]) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(this.settings[settingKey])) {
            attrs[settingKey] = currentValue
          }
        } else if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      this.isLoading = true
      this.$emit('submit', {
        settings: this.touchedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      if (attr === 'name') {
        return this.validationError('name.ar') || this.validationError('name.en')
      }
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
