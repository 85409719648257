<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
  >
    <b-card no-body>
      <b-card-header>
        <div>
          <b-card-title>
            <div>
              <span class="pr-75">{{settings.domain}}</span>
              <b-badge
                  pill
                  :variant="statusVariant"
                  size="sm"
                  class="font-small-3 font-weight-normal"
              >
                {{ $t(`modules.settings.status.${settings.status}`) }}
              </b-badge>
              <b-spinner
                  v-if="settings.status === 'initializing'"
                  variant="secondary"
                  label="Loading..."
                  small
                  class="ml-50"
              />
            </div>
            <div>
              <small>{{ $t('modules.settings.custom_domain_card.title') }}</small>
              <feather-icon
                  v-b-tooltip.hover.v-info
                  class="ml-25 text-info"
                  icon="InfoIcon"
                  size="18"
                  :title="$t('modules.settings.custom_domain_card.description')"
              />
            </div>
          </b-card-title>
        </div>

        <div class="d-flex flex-row align-items-center">
<!--          <b-button-->
<!--              variant="light"-->
<!--              @click="isBodyVisible = !isBodyVisible"-->
<!--          >-->
<!--            <feather-icon-->
<!--                icon="EditIcon"-->
<!--                size="18"-->
<!--            />-->
<!--            {{ $t('Edit') }}-->
<!--          </b-button>-->

            <span
                v-if="settings.status !== 'initializing'"
                class="mt-75"
                v-b-tooltip.hover.v-info
                :title="$t('modules.settings.inputs.is_domain_active:description')"
            >
            <i-input
                v-model="localSettings.is_active"
                type="switch"
                title=""
                @input="v => updateStatus(v)"
            >
              <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
              </span>
            <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
              </span>
          </i-input>
          </span>

<!--          <feather-icon-->
<!--              icon="Trash2Icon"-->
<!--              size="18"-->
<!--              class="cursor-pointer ml-2"-->
<!--              v-b-tooltip.hover.v-danger-->
<!--              :title="$t('Delete')"-->
<!--          />-->

          <b-button
              variant="outline-danger"
              @click="$emit('delete')"
              class="ml-50"
          >
            <feather-icon
                icon="Trash2Icon"
                size="18"
            />
            {{ $t('Delete') }}
          </b-button>
        </div>
      </b-card-header>

      <b-card-body v-if="isBodyVisible">
        <!-- form -->
        <b-form
            @submit.prevent="onSubmitHandler"
        >
          <b-row>
            <!-- alert -->
            <b-col
                cols="12"
                class="mt-75"
            >
              <b-alert
                  v-if="(settings.status !== 'active') && (settings.nameservers.length || settings.dns_records.length)"
                  show
                  variant="info"
                  class="mb-50"
              >
                <h4 class="alert-heading">
                  {{ $t('modules.settings._messages.dns_alert.title') }}
                </h4>
                <div class="alert-body" v-if="settings.nameservers.length">
                  <p>{{ $t('modules.settings._messages.nameservers_alert.line_1') }}</p>
                  <p v-for="nameserver in settings.nameservers"><code>{{ nameserver }}</code></p>
                  <div><small>{{ $t('modules.settings._messages.nameservers_alert.line_2') }}</small></div>
                </div>
                <div class="alert-body" v-else-if="settings.dns_records.length">
                  <p>{{ $t('modules.settings._messages.dns_alert.line_1') }}</p>
                  <div v-for="dns_record in settings.dns_records" class="d-flex align-items-center flex-row justify-content-around ltr my-2">
                      <span>
                      Type: <code>{{ dns_record.type }}</code>
                    </span>
                    <span>
                      Name: <code>{{ dns_record.name }}</code>
                    </span>
                    <span>
                      Content: <code>{{ dns_record.content }}</code>
                    </span>
                  </div>
                  <div><small>{{ $t('modules.settings._messages.dns_alert.line_2') }}</small></div>
                </div>
              </b-alert>
            </b-col>
            <!--/ alert -->
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupText,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAlert,
  BCardTitle,
  BCardHeader,
  BCardSubTitle,
  BAvatar,
  BBadge,
  BMediaBody,
  BMediaAside, BMedia, BCardBody, BImg, VBTooltip, BCardText, BOverlay, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from 'vue'
import { ValidationProvider } from 'vee-validate'
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";

export default {
  components: {
    BSpinner,
    BOverlay,
    BCardText,
    BImg,
    BCardActionsContainer,
    BCardBody,
    BMedia, BMediaAside, BMediaBody, BBadge, BAvatar,
    BCardSubTitle,
    BCardHeader, BCardTitle,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    ValidationProvider,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
    statusVariant() {
      switch(this.settings.status) {
        case 'active':
          return 'light-success';
        case 'inactive':
        case 'failed':
          return 'light-danger';
        case 'pending':
          return 'light-info';
      }
      return 'light-secondary'
    },
    isBodyVisible() {
     return this.settings.status !== 'active' && this.settings.status !== 'inactive'
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      const updatedAttributes = this.touchedAttributes
      this.isLoading = true
      this.$emit('submit', {
        settings: {
          id: this.settings.id,
          is_active: this.settings.is_active,
        },
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    updateStatus(newValue) {
      this.isLoading = true
      this.$emit('submit', {
        settings: {
          id: this.settings.id,
          is_active: newValue,
        },
        callback: (result, error) => {
          this.isLoading = false
        },
      })
    },
    validationError(attr) {
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    }
  },
  setup() {
    const refInputEl = ref(null)

    return {
      refInputEl,
    }
  },
}
</script>
