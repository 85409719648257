import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import themeSectionModel from './themeSectionModel'

const {
  fetchAll,
  fetchOne,
  // create,
  update,
  // deleteMany,
  // sorting,
} = useModelStoreModule(themeSectionModel)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    // create,
    update,
    // deleteMany,
    // sorting,
  },
}
