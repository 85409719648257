<template>
  <b-tab
    :active="false"
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="SettingsIcon"
        size="18"
        class="mr-50"
      />
      <span
        v-if="label"
        class="font-weight-bold"
      >
        {{ label }}
        <small v-if="isNew"><b-badge variant="primary">{{ $t('New') }}</b-badge></small>
      </span>
      <span
        v-else
        v-t="`modules.layout_settings.pages.${groupKey}`"
        class="font-weight-bold"
      />
    </template>

    <layout-section
      v-for="(sectionData, sectionIndex) in localSettingsGroup"
      :key="sectionIndex"
      :section-data="sectionData"
      @submit="payload => $emit('submit', {id: sectionData.id, ...payload})"
      @section-updated="section => $emit('section-updated', section)"
      @block-sorting="payload => $emit('block-sorting', payload)"
      @block-deleted="payload => $emit('block-deleted', payload)"
    />
  </b-tab>
</template>

<script>
import { BBadge, BTab } from 'bootstrap-vue'
import LayoutSection from '@/views/models/settings/layout-settings/LayoutSection.vue'

export default {
  components: {
    BBadge,
    LayoutSection,
    BTab,
  },
  emits: ['submit', 'block-sorting', 'block-deleted', 'section-updated'],
  props: {
    label: {
      type: String,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    groupKey: {
      type: [Number, String, null],
      default: null,
    },
    settingsGroup: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      localSettingsGroup: JSON.parse(JSON.stringify(this.settingsGroup)),
    }
  },
  watch: {
    settingsGroup(val) {
      this.localSettingsGroup = JSON.parse(JSON.stringify(val))
    },
  },
  beforeCreate() {

  },
  methods: {
    //
  },
}
</script>
