<template>
  <b-tabs
    ref="tabs"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    @changed="handleTabChangedEvent"
    @activate-tab="handleTabChangedEvent"
  >
    <try-new-theme-editor-card />
    <template
      v-for="(settingsGroup, groupKey) in settings"
    >
      <settings-tab
        v-if="groupKey !== 'sections'"
        :key="`settings-group-${groupKey}`"
        :active="false"
        :group-key="groupKey"
        :settings-group="settingsGroup"
        @submit="payload => onSubmitSection('main', payload.id, payload.data, payload.callback)"
        @section-updated="section => onSectionUpdated('main', section.id, section)"
        @block-sorting="payload => onBlockSorting(payload.sectionId, payload.data)"
        @block-deleted="payload => onBlockDeleted('main', payload.sectionId, payload.blockTemplateId, payload.blockId)"
      />
    </template>
    <template
      v-for="(settingsGroup, groupKey) in settings.sections"
    >
      <settings-tab
        :key="`settings-group-${settingsGroup.id}`"
        :active="false"
        :label="settingsGroup.name"
        :group-key="settingsGroup.id"
        :settings-group="[settingsGroup]"
        :is-new="settingsGroup.is_new"
        @submit="payload => onSubmitSection('sections', payload.id, payload.data, payload.callback)"
        @section-updated="section => onSectionUpdated('sections', section.id, section)"
        @block-sorting="payload => onBlockSorting(payload.sectionId, payload.data)"
        @block-deleted="payload => onBlockDeleted('sections', payload.sectionId, payload.blockTemplateId, payload.blockId)"
      />
    </template>
  </b-tabs>
</template>

<script>
import { BTabs } from 'bootstrap-vue'
import SettingsTab from '@/views/models/settings/layout-settings/SettingsTab.vue'
import {
  onBeforeMount, onMounted, onUnmounted,
} from 'vue'
import store from '@/store'
import themeSectionStoreModule from '@/views/models/settings/layout-settings/themeSectionStoreModule'
import themeBlockItemStoreModule from '@/views/models/settings/layout-settings/themeBlockItemStoreModule'
import TryNewThemeEditorCard from "@/views/models/settings/layout-settings/TryNewThemeEditorCard.vue";

export default {
  components: {
    TryNewThemeEditorCard,
    SettingsTab,
    BTabs,
  },
  emits: ['update:settings'],
  props: {
    settings: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      // settings: null,
    }
  },
  beforeCreate() {

  },
  methods: {
    handleTabChangedEvent() {
      // this.$nextTick(() => {
      //   // TODO: Subpage breadcrumb
      //   const selectedTabIndex = this.$refs.tabs.currentTab
      //   document.getElementById('appBreadcrumb')
      //     .getElementsByClassName('active')[0]
      //     .textContent = this.$refs.tabs.$refs.nav.children[selectedTabIndex].textContent
      // })
    },
  },
  setup(props, ctx) {
    const SECTION_STORE_MODULE_NAME = 'layout_sections'
    const SECTION_BLOCK_STORE_MODULE_NAME = 'theme_section_blocks'

    // Register module
    if (!store.hasModule(SECTION_STORE_MODULE_NAME)) store.registerModule(SECTION_STORE_MODULE_NAME, themeSectionStoreModule)
    if (!store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.registerModule(SECTION_BLOCK_STORE_MODULE_NAME, themeBlockItemStoreModule)
    onMounted(() => {
      if (!store.hasModule(SECTION_STORE_MODULE_NAME)) store.registerModule(SECTION_STORE_MODULE_NAME, themeSectionStoreModule)
      if (!store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.registerModule(SECTION_BLOCK_STORE_MODULE_NAME, themeBlockItemStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SECTION_STORE_MODULE_NAME)) store.unregisterModule(SECTION_STORE_MODULE_NAME)
      if (store.hasModule(SECTION_BLOCK_STORE_MODULE_NAME)) store.unregisterModule(SECTION_BLOCK_STORE_MODULE_NAME)
    })

    onBeforeMount(() => {
      //
    })

    const onSectionUpdated = (groupKey, sectionId, sectionData) => {
      const localSettings = JSON.parse(JSON.stringify(props.settings))
      const groupSections = localSettings[groupKey]
      groupSections.forEach((section, index) => {
        if (section.id === sectionId) {
          localSettings[groupKey][index] = sectionData
        }
      })

      ctx.emit('update:settings', localSettings)
    }

    const onSubmitSection = (groupKey, sectionId, newSettings, callback) => {
      store.dispatch(`${SECTION_STORE_MODULE_NAME}/update`, { id: sectionId, settings: newSettings })
        .then(response => {
          onSectionUpdated(groupKey, sectionId, response)
          callback(true, response)
        })
        .catch(() => {
          callback(false)
        }).finally(() => {
          callback(null)
        })
    }

    const onBlockSorting = (sectionId, blocksSorting) => {
      store.dispatch(`${SECTION_BLOCK_STORE_MODULE_NAME}/sorting`, { section_id: sectionId, items: blocksSorting.map((itemId, displayOrder) => ({ id: itemId, display_order: displayOrder + 1 })) })
        .then(() => {
        })
        .catch(() => {
        }).finally(() => {
        })
    }

    const onBlockDeleted = (groupKey, sectionId, blockTemplateId, blockId) => {
      const localSettings = JSON.parse(JSON.stringify(props.settings))
      const groupSections = localSettings[groupKey]
      groupSections.forEach((section, sectionIndex) => {
        if (section.id === sectionId) {
          section.blocks.forEach((blockTemplate, blockTemplateIndex) => {
            if (blockTemplate.id === blockTemplateId) {
              blockTemplate.items.forEach((blockItem, blockItemIndex) => {
                if (blockItem.block_id === blockId) {

                  localSettings[groupKey][sectionIndex].blocks[blockTemplateIndex].items.splice(blockItemIndex, 1)
                  ctx.emit('update:settings', localSettings)
                }
              })
            }
          })
        }
      })
    }

    return {
      onSubmitSection,
      onSectionUpdated,
      onBlockSorting,
      onBlockDeleted,
    }
  },
}
</script>
