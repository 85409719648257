<template>
  <b-card>
    <!-- form -->
    <b-form
      class="mt-2"
      @submit.prevent="onSubmitHandler"
    >
      <b-row>
        <b-col sm="6" v-if="canUseFeature('localized_logo')">
          <i-input
              v-model="localSettings.logo_localized"
              type="image"
              title="modules.settings.inputs.logo"
              :description="$t(`modules.settings.inputs.logo:description`)"
              imgClass="mw-100 h-auto w-auto"
              imgStyle="max-height: 80px"
              :translatable="true"
          />
        </b-col>
        <b-col sm="6" v-else>
          <i-input
              v-model="localSettings.logo"
              type="image"
              title="modules.settings.inputs.logo"
              :description="$t(`modules.settings.inputs.logo:description`)"
              imgClass="mw-100 h-auto w-auto"
              imgStyle="max-height: 80px"
          />
        </b-col>
        <b-col sm="6">
          <i-input
            v-model="localSettings.favicon"
            type="image"
            title="modules.settings.inputs.favicon"
            :description="$t(`modules.settings.inputs.favicon:description`)"
            imgClass="mw-100 h-auto w-auto"
            imgStyle="max-height: 80px"
          />
        </b-col>
        <b-col sm="6">
          <i-input
            v-model="localSettings.name"
            type="text"
            title="modules.settings.inputs.store_name"
            :translatable="true"
            :errors="{'ar': validationErrorsArray('name.ar'), 'en': validationErrorsArray('name.en')}"
            maxlength="100"
          />
        </b-col>
        <b-col sm="6">
          <i-input
            v-model="localSettings.industry.name"
            type="text"
            title="modules.settings.inputs.store_industry"
            disabled="disabled"
          />
        </b-col>
        <b-col sm="6">
          <i-input
            v-model="localSettings.email"
            type="email"
            maxlength="100"
            title="modules.settings.inputs.store_email"
            :errors="validationErrorsArray('email')"
          />

          <!--          <b-alert-->
          <!--            show-->
          <!--            variant="warning"-->
          <!--            class="mb-50"-->
          <!--          >-->
          <!--            <h4 class="alert-heading">-->
          <!--              Your email is not confirmed. Please check your inbox.-->
          <!--            </h4>-->
          <!--            <div class="alert-body">-->
          <!--              <b-link class="alert-link">-->
          <!--                Resend confirmation-->
          <!--              </b-link>-->
          <!--            </div>-->
          <!--          </b-alert>-->
        </b-col>
        <b-col sm="6">
          <i-input
            v-model="localSettings.phone"
            :dial-code.sync="localSettings.dialCode"
            type="tel"
            maxlength="100"
            title="modules.settings.inputs.store_phone"
            :errors="validationErrorsArray('phone')"
          />

          <!--          <b-alert-->
          <!--            show-->
          <!--            variant="warning"-->
          <!--            class="mb-50"-->
          <!--          >-->
          <!--            <h4 class="alert-heading">-->
          <!--              Your email is not confirmed. Please check your inbox.-->
          <!--            </h4>-->
          <!--            <div class="alert-body">-->
          <!--              <b-link class="alert-link">-->
          <!--                Resend confirmation-->
          <!--              </b-link>-->
          <!--            </div>-->
          <!--          </b-alert>-->
        </b-col>
        <b-col sm="12">
          <i-input
            v-model="localSettings.address"
            type="text"
            maxlength="1000"
            title="modules.settings.inputs.store_address"
            :translatable="true"
            :errors="{'ar': validationErrorsArray('address.ar'), 'en': validationErrorsArray('address.en')}"
          />
        </b-col>


        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!hasTouchedAttributes || isLoading"
              type="submit"
          >
            {{ $t(`Save Changes`) }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :disabled="isLoading"
              @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
      <hr>

      <b-alert
          variant="info"
          show
          dismissible
      >
        <h4 class="alert-heading">
          <feather-icon
              class="mr-25"
              icon="InfoIcon"
          />
          <span>{{ $t('modules.settings._messages.domain_settings_moved') }}</span>
        </h4>
        <div class="alert-body">
          <b-link
              v-t="'modules.settings._messages.domain_settings_moved:cta'"
              class="ml-25"
              :to="{ name: 'generalSettings', params: { category: 'store', subcategory: 'domain' } }"
          />
        </div>
      </b-alert>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupText,
  BInputGroupPrepend, BInputGroupAppend, BAlert, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from 'vue'
import { ValidationProvider } from 'vee-validate'
import {canUseFeature} from "@core/utils/utils";

export default {
  components: {
    BLink,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    ValidationProvider,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (settingKey === 'phone') {
          currentValue = `+${this.localSettings.dialCode}${this.localSettings.phone}`
        }
        if (typeof (this.settings[settingKey]) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(this.settings[settingKey])) {
            attrs[settingKey] = currentValue
          }
        } else if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    canUseFeature,
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      const updatedAttributes = this.touchedAttributes
      this.isLoading = true
      this.$emit('submit', {
        settings: updatedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      if (attr === 'name') {
        return this.validationError('name.ar') || this.validationError('name.en')
      }
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
