<template>
  <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
  >
    <div
        v-if="!isBodyVisible"
        class="media-list"
    >
      <b-media vertical-align="top">
        <template #aside>
          <b-img
              :src="require('@/assets/images/illustration/www.png')"
              blank-color="#ccc"
              width="64"
              alt="placeholder"
          />
        </template>
        <h4 class="media-heading">
          {{ $t('modules.settings.custom_domain_card.cta_title') }}

          <feather-icon
              v-b-tooltip.hover.v-primary
              class="ml-25 text-info"
              icon="InfoIcon"
              size="20"
              :title="$t('modules.settings.custom_domain_card.description')"
          />
        </h4>
        <b-card-text class="mb-0">
          <b-link @click="isBodyVisible = true">
            {{ $t('modules.settings.custom_domain_card.cta_subtitle') }}
          </b-link>
        </b-card-text>
      </b-media>
    </div>
    <b-card-actions
        v-else
        no-body
        action-close
        @close="isBodyVisible = false"
        :title="$t('modules.settings.custom_domain_card.title')"
    >
      <b-card-body>
        <b-alert
            show
            variant="info"
            class="mb-50"
            dismissible
        >
          <div class="alert-body">
            <feather-icon
                class="mr-25"
                icon="InfoIcon"
            />
            <span class="ml-25">{{ $t('modules.settings.custom_domain_card.description') }}</span>
          </div>
        </b-alert>
        <!-- form -->
        <b-form
            class="mt-2"
            @submit.prevent="onSubmitHandler"
        >
          <b-row>
            <b-col
                sm="12"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t(`modules.settings.inputs.custom_domain`)"
                  rules="required"
              >
                <b-form-group
                    :label="$t(`modules.settings.inputs.custom_domain`)"
                    label-for="custom_domain`"
                >
                  <b-input-group>
                    <b-form-input
                        id="domain"
                        v-model="localSettings.domain"
                        type="text"
                        minlength="5"
                        maxlength="100"
                        required
                        placeholder="mystore.com"
                    />
                    <template slot="append">
                      <b-input-group-append>
                        <b-input-group-text class="ltr bg-light">
                          https://
                        </b-input-group-text>
                      </b-input-group-append>
                    </template>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  :disabled="!hasTouchedAttributes || isLoading"
                  type="submit"
              >
                {{ $t(`Save Changes`) }}
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  :disabled="isLoading"
                  @click.prevent="resetForm"
              >
                {{ $t('Cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupText,
  BInputGroupPrepend,
  BInputGroupAppend,
  BAlert,
  BCardTitle,
  BCardHeader,
  BCardSubTitle,
  BAvatar,
  BBadge,
  BMediaBody,
  BMediaAside, BMedia, BCardBody, BImg, VBTooltip, BCardText, BLink, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from 'vue'
import { ValidationProvider } from 'vee-validate'
import BCardActionsContainer from "@core/components/b-card-actions/BCardActionsContainer.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BOverlay,
    BCardActions,
    BLink,
    BCardText,
    BImg,
    BCardActionsContainer,
    BCardBody,
    BMedia, BMediaAside, BMediaBody, BBadge, BAvatar,
    BCardSubTitle,
    BCardHeader, BCardTitle,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    ValidationProvider,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
      this.isBodyVisible = false
    },
    onSubmitHandler() {
      const updatedAttributes = this.touchedAttributes
      this.isLoading = true
      this.$emit('submit', {
        settings: updatedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    }
  },
  setup() {
    const refInputEl = ref(null)
    const isBodyVisible = ref(false)

    return {
      refInputEl,

      isBodyVisible,
    }
  },
}
</script>
