<template>
  <div><b-alert
         v-if="items.length && !items.filter(i=>i.is_active).length"
         show
         variant="warning"
         class="mb-50"
       >
         <div class="alert-body">
           <feather-icon
             class="mr-25"
             icon="InfoIcon"
           />
           <span
             v-t="'modules.settings._messages.no_active_domains'"
             class="ml-25"
           />
         </div>
       </b-alert>

    <settings-domain-sub-domain
      v-for="domain in subDomains"
      :key="domain.id"
      :settings="domain"
      @submit="payload => updateDomain(payload.settings, payload.callback)"
    />
    <hr>
    <settings-domain-custom-domain-edit
      v-for="domain in customDomains"
      :key="domain.id"
      :settings="domain"
      @submit="payload => updateDomain(payload.settings, payload.callback)"
      @delete="deleteDomain(domain.id)"
    />
    <template v-if="!customDomains.length">
      <settings-domain-custom-domain-add
        :settings="{'domain': null}"
        @submit="payload => updateDomain(payload.settings, payload.callback)"
      />
    </template>
  </div>
</template>

<script>
import {
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  computed, onMounted, onUnmounted, ref,
} from 'vue'
import { canUseFeature, catchException, toast } from '@core/utils/utils'
import SettingsDomainSubDomain
from '@/views/models/domains/add/SubDomainEdit.vue'
import SettingsDomainCustomDomainAdd
from '@/views/models/domains/add/CustomDomainAdd.vue'
import SettingsDomainCustomDomainEdit
from '@/views/models/domains/add/CustomDomainEdit.vue'
import DomainModel from '@/views/models/domains/domainModel'
import store from '@/store'
import domainStoreModule from '@/views/models/domains/domainStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {setStoreData} from "@/auth/utils";

export default {
  components: {
    SettingsDomainCustomDomainEdit,
    SettingsDomainCustomDomainAdd,
    SettingsDomainSubDomain,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      remoteErrors: {},
    }
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: {
    canUseFeature,
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const isLoading = ref(false)
    const items = ref([])
    const domainData = ref({})
    // Use toast
    const { t } = useI18nUtils()

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    const STORE_MODULE_NAME = DomainModel.name

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, domainStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, domainStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const refreshItem = itemId => {
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchOne`, itemId)
        .then(itemValue => {
          // Schedule a refresh for initializing items
          if (itemValue.status === 'initializing') {
            setTimeout(() => {
              refreshItem(itemValue.id)
            }, 5000)
          } else {
            items.value = items.value.map(item => {
              if (item.id === itemValue.id) {
                return itemValue
              }
              return item
            })
          }
        })
        .catch(e => {
          catchException(e)
          toast(
            'danger',
            t('message.error_fetching_list'),
            null,
            'AlertTriangleIcon',
          )
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    const fetchItems = () => {
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          const responseData = response.data
          items.value = responseData.data

          // Schedule a refresh for initializing items
          items.value.forEach(item => {
            if (item.status === 'initializing') {
              setTimeout(() => {
                refreshItem(item.id)
              }, 3000)
            }
          })

          // Workaround to reflect store updates to UI
          if (Object.hasOwn(responseData, 'store')) {
            setStoreData(responseData.store)
            // window.location.reload()
            // TODO: Refresh store url instead of page reload
          }
        })
        .catch(e => {
          catchException(e)
          toast(
            'danger',
            t('message.error_fetching_list'),
            null,
            'AlertTriangleIcon',
          )
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const refs = ref({})
    const { performSave } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      domainData,
      () => {
      },
      null,
      null,
      () => {
      },
    )
    const {
      deleteItem,
    } = useModelDelete(
      STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        fetchItems()
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )

    const updateDomain = (updateDomainData, callback) => {
      domainData.value = updateDomainData
      performSave(false, () => {
        fetchItems()
        callback(true)
      }, () => {
        callback(false)
      }, () => {
        callback(null)
      })
    }

    const deleteDomain = id => {
      deleteItem(id)
    }

    const subDomains = computed(() => items.value.filter(domain => !domain.is_custom_domain))

    const customDomains = computed(() => items.value.filter(domain => domain.is_custom_domain))

    fetchItems()

    return {
      refs,
      refInputEl,
      previewEl,
      inputImageRenderer,

      isLoading,
      items,
      subDomains,
      customDomains,
      updateDomain,
      deleteDomain,
    }
  },
}
</script>
