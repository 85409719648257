<template>
  <b-card>
    <b-form @submit.prevent="onSubmitHandler">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75"
             v-t="`modules.settings.pages.social_links`"
            />
          </div>
        </b-col>

        <!-- Social Media Links -->
        <b-col md="12" v-for="socialMedia in supportedSocialMedia" :key="`social-media-${socialMedia.key}`">
          <i-input
              v-model="localSettings[socialMedia.key]"
              type="text"
              :title="socialMedia.label"
              :placeholder="socialMedia.placeholder"
              :errors="validationErrorsArray(socialMedia.key)"
              maxlength="100"
              :input-bindings="{
                'style': 'text-align: left'
              }"
              :input-container-bindings="{
                'style': 'width: 80%'
              }"
          >
            <template #prepend>
                <div class="input-group-text" v-html="socialMedia.icon" />
            </template>
          </i-input>
        </b-col>
        <!--/ Social Media Links -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!hasTouchedAttributes || isLoading"
              type="submit"
          >
            {{ $t(`Save Changes`) }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              :disabled="isLoading"
              @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (typeof (this.settings[settingKey]) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(this.settings[settingKey])) {
            attrs[settingKey] = currentValue
          }
        } else if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},
      supportedSocialMedia: [
        {
          'key': 'facebook',
          'label': 'Facebook',
          'placeholder': 'https://www.facebook.com/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" d="M13.5 1A4.5 4.5 0 0 0 9 5.5V9H6.5a0.5 0.5 0 0 0 -0.5 0.5v4a0.5 0.5 0 0 0 0.5 0.5H9v8.5a0.5 0.5 0 0 0 0.5 0.5h4a0.5 0.5 0 0 0 0.5 -0.5V14h2.5a0.5 0.5 0 0 0 0.485 -0.379l1 -4A0.5 0.5 0 0 0 17.5 9H14V7.5A1.5 1.5 0 0 1 15.5 6h2a0.5 0.5 0 0 0 0.5 -0.5v-4a0.5 0.5 0 0 0 -0.5 -0.5h-4Z" stroke-width="1"></path></svg>'
        },
        {
          'key': 'instagram',
          'label': 'Instagram',
          'placeholder': 'https://www.instagram.com/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" fill-rule="evenodd" d="M7.5 0.75A6.75 6.75 0 0 0 0.75 7.5v9a6.75 6.75 0 0 0 6.75 6.75h9a6.75 6.75 0 0 0 6.75 -6.75v-9A6.75 6.75 0 0 0 16.5 0.75h-9ZM2.25 7.5c0 -2.9 2.35 -5.25 5.25 -5.25h9c2.9 0 5.25 2.35 5.25 5.25v9c0 2.9 -2.35 5.25 -5.25 5.25h-9a5.25 5.25 0 0 1 -5.25 -5.25v-9ZM19.5 6.25a1.25 1.25 0 1 1 -2.5 0 1.25 1.25 0 0 1 2.5 0ZM12 7.75a4.25 4.25 0 1 0 0 8.5 4.25 4.25 0 0 0 0 -8.5ZM6.25 12a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1 -11.5 0Z" clip-rule="evenodd" stroke-width="1"></path></svg>'
        },
        {
          'key': 'snapchat',
          'label': 'Snapchat',
          'placeholder': 'https://www.snapchat.com/add/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" d="M7.926 2.432C9.298 1.266 10.93 1 12 1c1.07 0 2.702 0.266 4.074 1.432 1.284 1.091 2.269 2.91 2.409 5.836l0.296 -0.118a2.297 2.297 0 0 1 1.953 4.148l-2.032 0.931c0.267 0.405 0.698 0.923 1.243 1.468 0.75 0.75 1.66 1.494 2.522 1.985 0.302 0.173 0.566 0.453 0.674 0.795a0.998 0.998 0 0 1 -0.328 1.103c-0.547 0.45 -1.383 0.812 -2.357 0.9 -0.08 0.387 -0.262 0.699 -0.546 0.928 -0.366 0.295 -0.83 0.393 -1.235 0.432 -0.305 0.03 -0.637 0.03 -0.93 0.029l-0.269 0c-0.385 0.005 -0.633 0.03 -0.78 0.092 -0.2 0.084 -0.445 0.236 -0.761 0.445l-0.19 0.127c-0.26 0.173 -0.552 0.369 -0.862 0.554 -0.78 0.465 -1.748 0.913 -2.881 0.913 -1.133 0 -2.101 -0.448 -2.881 -0.913 -0.31 -0.185 -0.602 -0.38 -0.862 -0.554a53.207 53.207 0 0 0 -0.19 -0.127c-0.316 -0.21 -0.56 -0.36 -0.76 -0.445 -0.148 -0.062 -0.396 -0.087 -0.781 -0.091l-0.269 -0.001c-0.293 0 -0.625 0 -0.93 -0.029 -0.405 -0.039 -0.869 -0.137 -1.235 -0.432a1.555 1.555 0 0 1 -0.546 -0.928c-0.974 -0.088 -1.81 -0.45 -2.357 -0.9a0.998 0.998 0 0 1 -0.328 -1.103c0.108 -0.342 0.372 -0.622 0.674 -0.795 0.862 -0.491 1.772 -1.235 2.522 -1.985 0.545 -0.545 0.976 -1.063 1.243 -1.468l-2.032 -0.93A2.297 2.297 0 0 1 5.221 8.15l0.296 0.117c0.14 -2.925 1.125 -4.745 2.41 -5.836Z" stroke-width="1"></path></svg>'
        },
        {
          'key': 'twitter',
          'label': 'Twitter',
          'placeholder': 'https://www.twitter.com/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" d="M1 19c15.617 6.062 20.038 -5.025 19.905 -10.5C22.173 8.156 23 7.292 23 6.406c-1.048 0.55 -1.595 0.394 -2.119 0 1.128 -0.768 1.071 -1.863 1.071 -2.619 -0.527 0.277 -1.503 0.96 -2.619 1.048 -0.745 -1.166 -2.619 -2.095 -5.238 -1.048 -2.619 1.048 -3.143 3.842 -2.619 5.238 -3.352 0 -7.333 -3.492 -8.905 -5.238 -1.883 2.688 0.5 5.353 1.572 6.286 -0.715 0.214 -1.572 0 -2.095 -0.524 0 2.991 2.619 4.19 3.666 4.715H3.62c0 2.095 2.444 2.968 3.667 3.142 -0.838 0.838 -4.215 1.596 -6.286 1.596Z" stroke-width="1"></path></svg>'
        },
        {
          'key': 'whatsapp',
          'label': 'Whatsapp',
          'placeholder': 'https://www.wa.me/phone-number',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" fill-rule="evenodd" d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11 -4.925 11 -11 11c-1.936 0 -3.757 -0.5 -5.338 -1.38L1.4 22.6l0.98 -5.262A10.952 10.952 0 0 1 1 12Zm6.147 -5.353c0.654 -0.655 1.527 -0.713 1.998 -0.69 0.435 0.02 0.776 0.272 0.984 0.564l0.952 1.334a1.5 1.5 0 0 1 -0.16 1.932l-0.674 0.673c0.223 0.544 0.67 1.42 1.272 2.021s1.477 1.05 2.02 1.273l0.675 -0.674a1.5 1.5 0 0 1 1.931 -0.16l1.335 0.951c0.291 0.209 0.544 0.55 0.564 0.984 0.022 0.471 -0.036 1.344 -0.69 1.999 -1.067 1.066 -2.741 1.077 -4.264 0.659 -1.55 -0.426 -3.13 -1.34 -4.197 -2.406 -1.065 -1.066 -1.98 -2.646 -2.406 -4.197 -0.418 -1.523 -0.407 -3.197 0.66 -4.263Z" clip-rule="evenodd" stroke-width="1"></path></svg>'
        },
        {
          'key': 'telegram',
          'label': 'Telegram',
          'placeholder': 'https://t.me/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50" height="24" width="24"><path fill="#000000" fill-rule="evenodd" d="M46.137,6.552c-0.75-0.636-1.928-0.727-3.146-0.238l-0.002,0C41.708,6.828,6.728,21.832,5.304,22.445\tc-0.259,0.09-2.521,0.934-2.288,2.814c0.208,1.695,2.026,2.397,2.248,2.478l8.893,3.045c0.59,1.964,2.765,9.21,3.246,10.758\tc0.3,0.965,0.789,2.233,1.646,2.494c0.752,0.29,1.5,0.025,1.984-0.355l5.437-5.043l8.777,6.845l0.209,0.125\tc0.596,0.264,1.167,0.396,1.712,0.396c0.421,0,0.825-0.079,1.211-0.237c1.315-0.54,1.841-1.793,1.896-1.935l6.556-34.077\tC47.231,7.933,46.675,7.007,46.137,6.552z M22,32l-3,8l-3-10l23-17L22,32z"></path></svg>'
        },
        {
          'key': 'tiktok',
          'label': 'TikTok',
          'placeholder': 'https://www.tiktok.com/@username',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 50" height="24" width="24"><path fill="#000000" d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path></svg>'
        },
        {
          'key': 'youtube',
          'label': 'Youtube',
          'placeholder': 'https://www.youtube.com/channel/user-name',
          'icon': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24"><path fill="#000000" fill-rule="evenodd" d="M1.172 7.693A55.991 55.991 0 0 0 1 12c0 1.632 0.08 3.167 0.182 4.44 0.146 1.817 1.531 3.206 3.318 3.33 1.794 0.125 4.305 0.23 7.5 0.23 3.195 0 5.706 -0.105 7.5 -0.23 1.787 -0.124 3.172 -1.513 3.318 -3.33 0.102 -1.273 0.182 -2.808 0.182 -4.44 0 -1.576 -0.074 -3.06 -0.172 -4.307a3.727 3.727 0 0 0 -3.46 -3.46C17.59 4.107 15.164 4 12 4c-3.164 0 -5.591 0.107 -7.368 0.233a3.727 3.727 0 0 0 -3.46 3.46ZM15.5 12 10 9v6l5.5 -3Z" clip-rule="evenodd" stroke-width="1"></path></svg>'
        },
        {
          'key': 'pinterest',
          'label': 'Pinterest',
          'placeholder': 'https://www.pinterest.com/username',
          'icon': '<svg height="24px" width="24px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="current" stroke="current" d="M15.236,0.308c-8.015,0 -14.512,6.487 -14.512,14.488c0,6.141 3.822,11.388 9.221,13.498c-0.132,-1.144 -0.239,-2.909 0.047,-4.161c0.263,-1.133 1.697,-7.202 1.697,-7.202c0,0 -0.43,-0.871 -0.43,-2.147c0,-2.015 1.17,-3.517 2.627,-3.517c1.242,0 1.84,0.93 1.84,2.039c0,1.24 -0.789,3.1 -1.207,4.829c-0.346,1.443 0.729,2.623 2.15,2.623c2.58,0 4.563,-2.718 4.563,-6.63c0,-3.47 -2.497,-5.89 -6.068,-5.89c-4.132,0 -6.557,3.088 -6.557,6.284c0,1.24 0.478,2.576 1.075,3.303c0.119,0.143 0.131,0.274 0.095,0.417c-0.107,0.453 -0.358,1.443 -0.406,1.646c-0.059,0.262 -0.215,0.322 -0.489,0.191c-1.816,-0.847 -2.95,-3.482 -2.95,-5.617c0,-4.567 3.32,-8.764 9.591,-8.764c5.028,0 8.946,3.577 8.946,8.371c0,4.996 -3.154,9.015 -7.525,9.015c-1.469,0 -2.855,-0.764 -3.321,-1.67c0,0 -0.728,2.767 -0.907,3.446c-0.323,1.264 -1.207,2.838 -1.804,3.804c1.362,0.418 2.795,0.644 4.3,0.644c8.014,0 14.512,-6.487 14.512,-14.488c0.024,-8.025 -6.474,-14.512 -14.488,-14.512Z"></path></svg>'
        },
      ]
    }
  },
  methods: {
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      this.isLoading = true
      this.$emit('submit', {
        settings: this.touchedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    }
  },
}
</script>
